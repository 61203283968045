import initialState from 'store/initialState';
import { FETCHOPTIONS } from 'actions/actionTypes';

const options = (state = initialState.options, action) => {
  switch (action.type) {
    case FETCHOPTIONS.REQUEST:
      return { ...state, fetchingOption: true, error: null };
    case FETCHOPTIONS.SUCCESS:
      return { ...state, fetchingOption: false, data: action.payload };
    case FETCHOPTIONS.FAILURE:
      return { ...state, fetchingOption: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default options;
