import initialState from 'store/initialState';
import { FETCHINFOFROMSSSINNER } from 'actions/actionTypes';

const fetchingFSSS = (state = initialState.fetchingFSSS, action) => {
  switch (action.type) {
    case FETCHINFOFROMSSSINNER.REQUEST:
      return { ...state, fetchingDataFromSSS: true, error: null };
    case FETCHINFOFROMSSSINNER.SUCCESS:
      return { ...state, fetchingDataFromSSS: false, data: action.payload };
    case FETCHINFOFROMSSSINNER.FAILURE:
      return { ...state, fetchingDataFromSSS: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default fetchingFSSS;
