import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as userActions from 'actions';
import _ from 'lodash';
import AppLoader from 'components/Templates/AppLoader';
import Home from './pages/index';
import HeaderTab from './components/Templates/HeaderTab';

function MainApp({ userId }) {
  const dispatch = useDispatch();
  const userFetching = useSelector(state => _.get(state.user, 'fetching', false));
  const vendorFetching = useSelector(state => _.get(state.vendor, 'fetchingVendor', false));
  useEffect(() => {
    dispatch(userActions.loadUserDetails(userId));
  }, [dispatch, userId]);

  if (!userFetching && !vendorFetching) {

    return (
      <div className="wrapper dei-wrapper">
        <HeaderTab caption="DIVERSITY EQUITY & INCLUSION (DE&I)" />
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/suite/diversity-equity-inclusion"
              component={Home}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  } else {
    return <AppLoader show />;
  }
}

export default MainApp;
