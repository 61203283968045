import axios from 'axios';
import qs from 'qs';
import { getIdToken } from '../Authenticator';
import { getOtmmBaseUrl } from '../utils/util';

const baseURL = process.env.REACT_APP_BFF;
console.log('process.env',process.env,process.env.REACT_APP_BFF)

const getHeaders = async () => {
  const token = (await getIdToken()).getJwtToken();
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
};
const getUploadHeaders = async () => {
  return {
    'Content-Type': 'multipart/form-data'
  };
};

const postRequest = async (url, data) => {
  return axios.post(`${baseURL}${url}`, data, {
    headers: await getHeaders()
  });
};

const getRequest = async (url, params = {}) => {
  return axios.get(`${baseURL}${url}`, {
    headers: await getHeaders(),
    params,
    paramsSerializer: () => qs.stringify(params)
  });
};

const getRequestFromStep = async (url, params = {}) => {
  const username = 'mdm_test'
  const password = 'test123'
  var authorizationBasic = window.btoa(username + ':' + password);
  return axios.get(`/vendor/${url}`, {
    withCredentials: true,
    "headers": {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Basic " + authorizationBasic
    }
  });
};

const putRequest = async (url, data) => {
  return axios.put(`${baseURL}${url}`, data, {
    headers: await getHeaders()
  });
};

const patchRequest = async (url, data) => {
  return axios.patch(`${baseURL}${url}`, data, {
    headers: await getHeaders()
  });
};

const deleteRequest = async url => {
  return axios.delete(`${baseURL}${url}`, {
    headers: await getHeaders()
  });
};

const FileUploadRequest = async (url, data) => {
  let baseDomain = getOtmmBaseUrl();
  return axios
    .post(`${baseDomain}${url}`, data, {
      headers: await getUploadHeaders()
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      return error.response;
    });
};
const FileCombineRequest = async(data)=>{
  return axios.all(data)
}
const getPreviewFile = async(url,s3Id) =>{
  let baseDomain = getOtmmBaseUrl();
  return axios
    .get(`${baseDomain}${url}/${s3Id}`, {
      headers: await getUploadHeaders()
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      return error.response;
    });
}

const axiosInstanceDocument = axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

axiosInstanceDocument.interceptors.request.use(async (request) => {
  const token = (await getIdToken()).getJwtToken();
  request.headers.Authorization = 'Bearer ' + token;
  return request;
});

axiosInstanceDocument.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // your failure logic
    } else if (response.status === 500) {
      return 'Please try again later';
    }
    return response;
  },
  function (error) {
    return error;
  }
);
const fileUploadToOTCSRequest = async (url, data) => {
  return axiosInstanceDocument.post(`${url}`, data, {});
};

const fileDownloadFromOTCSRequest = async (url, params = {}) => {
  return axiosInstanceDocument.get(`${url}`, {
    params,
    paramsSerializer: () => qs.stringify(params),
    responseType: "blob"
  });
};

export { postRequest, getRequest, putRequest, deleteRequest, getHeaders, patchRequest,FileUploadRequest,FileCombineRequest ,getPreviewFile,getRequestFromStep,fileUploadToOTCSRequest,fileDownloadFromOTCSRequest};
