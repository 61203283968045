import { action } from 'reduxHelpers';
import { USER, FETCHCOMPANY,FETCHOPTIONS,CERTIFICATIONSOP,COMMITINFOTOSSSINNER,RESETSELECTEDITEM ,FETCHINFOFROMSSSINNER,CLEARFORMDATA,COMMITINFOTOSTEP,CERTIFICATIONTOOTCS,UPDATEFOLDERID,DOWNLOADFILE,FETCHSUPPLIERS,FETCHSUBSUPPLIERS,RESELECTSUBVENDORS} from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const fetchCompany = (info) => action(FETCHCOMPANY.REQUEST, { info });
const resetSelectedItem = () => action(RESETSELECTEDITEM.REQUEST, {});
const fetchOptions = () => action(FETCHOPTIONS.REQUEST)
const oprationCertification = (suvc,contactId,uploadList,info,history)=> action(CERTIFICATIONSOP.REQUEST,{suvc,contactId,uploadList,info,history})
const saveInfoToSSSInner = (info,history) => action(COMMITINFOTOSSSINNER.REQUEST,{info,history})
const fetchInfoFromSSSInner = (suvc) =>action(FETCHINFOFROMSSSINNER.REQUEST,{suvc})
const clearFormData = () => action(CLEARFORMDATA.REQUEST,{})
const saveInfoToStep = (suvc,info,history) => action(COMMITINFOTOSTEP.REQUEST,{suvc,info,history})
const patchCertToOTCS = (suvc,contactId,uploadFile,reservation_id) => action(CERTIFICATIONTOOTCS.REQUEST,{suvc,contactId,uploadFile,reservation_id})
const updateFolderId = (info) =>action(UPDATEFOLDERID.REQUEST,{info})
const downloadFile = (documentId, fileName) => action(DOWNLOADFILE.REQUEST, { documentId, fileName });
const fetchSuppliers = () => action(FETCHSUPPLIERS.REQUEST);
const fetchSubSuppliers = (info) =>action(FETCHSUBSUPPLIERS.REQUEST, { info });
const clearSubVendors = () => action(RESELECTSUBVENDORS.REQUEST, {});


export { loadUserDetails, fetchCompany, fetchOptions ,oprationCertification,resetSelectedItem, saveInfoToSSSInner,fetchInfoFromSSSInner,clearFormData,saveInfoToStep,patchCertToOTCS,updateFolderId,downloadFile,fetchSuppliers,fetchSubSuppliers,clearSubVendors};

