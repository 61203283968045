import {openNotification,openSubmitSuccessInfo} from 'components/Templates/Notification';

function* showNotificationAsync(notificationAction) {
  const { message, description, className } = notificationAction;
  yield openNotification({ message, description, className });
}
function* showSubmitSuccessInfo(payload) {
  console.log("###################")
  yield openSubmitSuccessInfo({...payload});
}


export {showNotificationAsync,showSubmitSuccessInfo};
