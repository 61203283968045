import initialState from 'store/initialState';
import { CERTIFICATIONTOOTCS } from 'actions/actionTypes';

const certificationToOtcs = (state = initialState.certificationToOtcs, action) => {
  switch (action.type) {
    case CERTIFICATIONTOOTCS.REQUEST:
      return { ...state, fetchingcertStatus: true, error: null };
    case CERTIFICATIONTOOTCS.SUCCESS:
      return { ...state, fetchingcertStatus: false, data: action.payload };
    case CERTIFICATIONTOOTCS.FAILURE:
      return { ...state, fetchingcertStatus: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default certificationToOtcs;
