import { combineReducers } from 'redux';
import user from './UserReducer';
import selectedItem from './CompanyReducer';
import options from './OptionsReducer';
import fetchingFSSS from './infoTransBetweenSSS';
import certification from './certificationReducer';
import fetchingFSTEP from './infoTransBetweenStep';
import certificationToOtcs from './certificationToOTCSReducer';
import fetchUpdateFolderId from './updateFolderIdReducer';
import vendor from './vendorReducer'
import subVendor from './subVendorReducer'

export default combineReducers({
  user,
  options,
  selectedItem,
  fetchingFSSS,
  certification,
  fetchingFSTEP,
  certificationToOtcs,
  fetchUpdateFolderId,
  vendor,
  subVendor
});
