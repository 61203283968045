import initialState from 'store/initialState';
import { FETCHCOMPANY, RESETSELECTEDITEM } from 'actions/actionTypes';

const company = (state = initialState.selectedItem, action) => {
  switch (action.type) {
    case FETCHCOMPANY.REQUEST:
      return { ...state, fetching: true, error: null };
    case FETCHCOMPANY.SUCCESS:
      return { 
        ...state,
        fetching: false,
        error: null,
        ...action.payload
      };
    case FETCHCOMPANY.FAILURE:
      return { ...state, fetching: false, error: action.payload };
    case RESETSELECTEDITEM.REQUEST:
      return { 
        ...state,
        fetching: false,
        suvc: null,
        company: null,
        error: null
      };
    default:
      return state;
  }
};

export default company;
