import React, { useEffect, useState } from 'react';
import { Form, Button, Select, DatePicker, Row, Col, Upload, Space, Spin, message } from 'antd';
import { fileUploadToOTCSRequest } from '../_http';
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as deiActions from 'actions';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { transformCertName, getFileNameWithoutExtension, getUniqueId, CertTypeMap, DiverseTypeMap, FederalTypeMap } from '../utils/util'
import { checkUserHasSearchPermissions } from '../utils/util'

const { Option } = Select;

const DetailInfo = ({ t, isShow, setShowDetail, suvcDetail, history, optionLists }) => {



  const [form] = Form.useForm();
  const dispatch = useDispatch();


  const dataFromSSSInner = useSelector(state => _.get(state.fetchingFSSS, 'data', null));
  const updateFolderStatu = useSelector(state => _.get(state.fetchUpdateFolderId, 'fetchingUpdateFolderIdStatus', null));
  const fetchSSSLoading = useSelector(state => _.get(state.fetchingFSSS, 'fetchingDataFromSSS', null));
  const permissions = useSelector(state => _.get(state.user, 'data.permissions', []));
  const hasSearchPermissions = checkUserHasSearchPermissions(permissions);
  const [oldItem, setoldItem] = useState([])
  const [fetchParam, setfetchParam] = useState({})
  const [infoFromStep, setinfoFromStep] = useState({})
  const [needUpdateFolderIdLoading,setneedUpdateFolderIdLoading] = useState(false)

  // this Effect for update forlderid suvc and companyname when do dispatch(deiActions.updateFolderId)
  useEffect(() => {
    console.log('updateFolderStatu', updateFolderStatu)
    if (!updateFolderStatu) {
      setneedUpdateFolderIdLoading(false)
      let oldFolderId = localStorage.getItem('vendor') !== 'undefined' ? JSON.parse(localStorage.getItem('vendor')) : null
      let folder = null
      if (oldFolderId && oldFolderId.subSuvcs) {
        folder = oldFolderId.subSuvcs.filter(res => {
          return res.suvc === fetchParam.suvc
        })
      }
      console.log(folder)
      if (folder && folder.length > 0) {

        setfetchParam({
          suvc: suvcDetail.suvc,
          vendor_name: suvcDetail.companyName,
          folderId: folder[0].folderId
        })
       
      }

    }
  }, [updateFolderStatu])


  // this effect for update exp suvc forlderId vendor_name param for final patch method,and render step info to front end
  useEffect(() => {
    if (isShow === true && suvcDetail && suvcDetail.suvc) {
      let folderId = null
      folderId = localStorage.getItem('vendor') !== 'undefined' ? JSON.parse(localStorage.getItem('vendor')).subSuvcs.filter(res => res.suvc === suvcDetail.suvc)[0].folderId : null
      setfetchParam({
        suvc: suvcDetail.suvc,
        vendor_name: suvcDetail.companyName,
        folderId: folderId,
      })
      console.log('suvcDetail', suvcDetail)
     
      renderStepInfoInSSS(suvcDetail.deiInfo)
      //this logic for MVP3 start
      // if (suvcDetail.deiInfo) {
      //   isFetchStep = true
      //   renderStepInfoInSSS(suvcDetail.deiInfo)
      // } else {
      //   isFetchStep = false
      //   dispatch(deiActions.fetchInfoFromSSSInner(fetchParam.suvc));
      // }
      //this logic for MVP3 end
    }

  }, [suvcDetail, isShow])


  const renderStepInfoInSSS = (deiInfo) => {
    form.resetFields();
    if (deiInfo.MBEExpDate) {
      deiInfo.MBEExpDate = moment(new Date(deiInfo.MBEExpDate))
    }
    if (deiInfo.WBEExpDate) {
      deiInfo.WBEExpDate = moment(new Date(deiInfo.WBEExpDate))
    }

    if (deiInfo.federalArr && deiInfo.federalArr.length > 0) {
      deiInfo.federalArr.map(res => {
        if (res.FederalExpDate) {
          res.FederalExpDate = moment(new Date(res.FederalExpDate))
        }
      })
    }

    // use third part param to update certification file
    if (deiInfo.MBECertFromBff) {
      if (deiInfo['MBECertType'] === 'THIRD PARTY CERTIFICATION') {
        deiInfo['MBETempCert'] = [deiInfo.MBECertFromBff]

      } else {
        deiInfo['MBESelfTempCert'] = [deiInfo.MBECertFromBff]
      }

    }else{
      deiInfo['MBETempCert'] =[]
      deiInfo['MBESelfTempCert'] =[]
    }


    if (deiInfo.WBECertFromBff) {
      if (deiInfo['WBECertType'] === 'THIRD PARTY CERTIFICATION') {
        deiInfo['WBETempCert'] = [deiInfo.WBECertFromBff]

      } else {
        deiInfo['WBESelfTempCert'] = [deiInfo.WBECertFromBff]
      }
    }else{
      deiInfo['WBETempCert'] =[]
      deiInfo['WBESelfTempCert'] =[]
    }


    if (deiInfo.federalArr && deiInfo.federalArr.length > 0) {
      deiInfo.federalArr.map(res => {
        if (res.FederalCertFromBff) {
          if (res.FederalCertType === 'THIRD PARTY CERTIFICATION') {
            res.FederalTempCert = [res.FederalCertFromBff]

          } else {
            res.FederalSelfTempCert = [res.FederalCertFromBff]
          }

        }else{
          res.FederalTempCert = []
          res.FederalSelfTempCert = []
        }
      })

    }

    form.setFieldsValue(deiInfo)

    console.log('dataFromSTEPr*********************', deiInfo)
    setinfoFromStep(deiInfo)

  }

  //logic for MVP3 start
  // useEffect(() => {
  //   form.resetFields();


  //   if (dataFromSSSInner && !isFetchStep) {
  //     if (dataFromSSSInner.MBEExpDate) {
  //       dataFromSSSInner.MBEExpDate = moment(new Date(dataFromSSSInner.MBEExpDate))
  //     }
  //     if (dataFromSSSInner.WBEExpDate) {
  //       dataFromSSSInner.WBEExpDate = moment(new Date(dataFromSSSInner.WBEExpDate))
  //     }

  //     if (dataFromSSSInner.federalArr && dataFromSSSInner.federalArr.length > 0) {
  //       dataFromSSSInner.federalArr.map(res => {
  //         if (res.FederalExpDate) {
  //           res.FederalExpDate = moment(new Date(res.FederalExpDate))
  //         }
  //         if (res.FederalCertFromBff && res.FederalCertFromBff.length > 0) {
  //           res.FederalCertType = res.FederalCertFromBff[0].FederalCertType
  //           if (res.FederalCertType == 'THIRD PARTY CERTIFICATION') {
  //             res.FederalCert = res.FederalCertFromBff
  //             res.FederalTempCert = res.FederalCertFromBff
  //           } else if (res.FederalCertType == 'SELF-SELECTED') {
  //             res.FederalSelfTempCert = res.FederalCertFromBff
  //             res.FederalSelfCert = res.FederalCertFromBff
  //           }
  //         }
  //       })
  //     }

  //     form.setFieldsValue(dataFromSSSInner)
  //     if (dataFromSSSInner.MBECertFromBff && dataFromSSSInner.MBECertFromBff.length > 0) {
  //       form.setFieldValue('MBECertType', dataFromSSSInner.MBECertFromBff[0].MBECertType)

  //       if (dataFromSSSInner.MBECertFromBff[0].MBECertType == 'THIRD PARTY CERTIFICATION') {
  //         form.setFieldValue('MBECert', dataFromSSSInner.MBECertFromBff)
  //         form.setFieldValue('MBETempCert', dataFromSSSInner.MBECertFromBff)

  //       } else if (dataFromSSSInner.MBECertFromBff[0].MBECertType == 'SELF-SELECTED') {
  //         form.setFieldValue('MBESelfTempCert', dataFromSSSInner.MBECertFromBff)
  //         form.setFieldValue('MBESelfCert', dataFromSSSInner.MBECertFromBff)

  //       }
  //     }

  //     if (dataFromSSSInner.WBECertFromBff && dataFromSSSInner.WBECertFromBff.length > 0) {
  //       form.setFieldValue('WBECertType', dataFromSSSInner.WBECertFromBff[0].WBECertType)
  //       if (dataFromSSSInner.WBECertFromBff[0].WBECertType == 'THIRD PARTY CERTIFICATION') {
  //         form.setFieldValue('WBETempCert', dataFromSSSInner.WBECertFromBff)
  //         form.setFieldValue('WBECert', dataFromSSSInner.WBECertFromBff)

  //       } else if (dataFromSSSInner.WBECertFromBff[0].WBECertType == 'SELF-SELECTED') {
  //         form.setFieldValue('WBESelfTempCert', dataFromSSSInner.WBECertFromBff)
  //         form.setFieldValue('WBESelfCert', dataFromSSSInner.WBECertFromBff)

  //       }
  //     }
  //     console.log('dataFromSSSInner*********************', dataFromSSSInner)

  //   }
  // }, [dataFromSSSInner])
  //logic for MVP3 end


  

  //main method to combine data for otcs upload feature
  const handleUpload = async (option, partName) => {
    console.log('partName', partName)
    console.log('option', option)
    if(option.file.name.indexOf('_')>0){
      option.file= new File([option.file],option.file.name.replaceAll('_',' ')) 
    }
    //let upload plugin have a loading statu
    const mockItem = {
      uid: '1',
      name: option.file.name,
      status: 'uploading',
      url: '',
      percent: 99,
    };


    let fileName = ''
    let setFieldName = ''
    let originUploadObj = {
      folderId: fetchParam.folderId ? fetchParam.folderId + '' : '',
      uniqfileName: `${getFileNameWithoutExtension(option.file.name)}_${getUniqueId()}`,
      document: option.file,
      suvc: fetchParam.suvc
    }
    let uploadObj = {}
    switch (partName) {
      case 'WBE':
        fileName = transformCertName(fetchParam.suvc, 'WBE', '', form.getFieldValue('WBECertType'), option.file.name, form.getFieldValue('WBEExpDate'))
        uploadObj = {
          ...originUploadObj,
          section: 'WBE',
          sectionAlt: '',
          certType: CertTypeMap[form.getFieldValue('WBECertType')],
          expiryDate: moment(form.getFieldValue('WBEExpDate')).format("YYYY-MM-DD")
        }
        //if setfield to wbecert or mbecert will cause forever loop error, so need a third param
        form.setFieldValue('WBETempCert', [mockItem])
        setFieldName = 'WBETempCert'
        break;
      case 'WBESelf':
        fileName = transformCertName(fetchParam.suvc, 'WBE', '', form.getFieldValue('WBECertType'), option.file.name)
        uploadObj = {
          ...originUploadObj,
          section: 'WBE',
          sectionAlt: '',
          certType: CertTypeMap[form.getFieldValue('WBECertType')],
          expiryDate: ''
        }
        form.setFieldValue('WBESelfTempCert', [mockItem])
        setFieldName = 'WBESelfTempCert'
        break;
      case 'MBE':
        fileName = transformCertName(fetchParam.suvc, 'MBE', form.getFieldValue('MBE_DiverseClassfication'), form.getFieldValue('MBECertType'), option.file.name, form.getFieldValue('MBEExpDate'))
        uploadObj = {
          ...originUploadObj,
          section: 'MBE',
          sectionAlt: DiverseTypeMap[form.getFieldValue('MBE_DiverseClassfication')],
          certType: CertTypeMap[form.getFieldValue('MBECertType')],
          expiryDate: moment(form.getFieldValue('MBEExpDate')).format("YYYY-MM-DD")
        }
        form.setFieldValue('MBETempCert', [mockItem])
        setFieldName = 'MBETempCert'
        break;
      case 'MBESelf':
        fileName = transformCertName(fetchParam.suvc, 'MBE', form.getFieldValue('MBE_DiverseClassfication'), form.getFieldValue('MBECertType'), option.file.name)
        uploadObj = {
          ...originUploadObj,
          section: 'MBE',
          sectionAlt: DiverseTypeMap[form.getFieldValue('MBE_DiverseClassfication')],
          certType: CertTypeMap[form.getFieldValue('MBECertType')],
          expiryDate: ''
        }
        form.setFieldValue('MBESelfTempCert', [mockItem])
        setFieldName = 'MBESelfTempCert'
        break;
      default:

    }
    console.log('fileName', fileName)
    uploadCertService(option, fileName, partName, setFieldName, uploadObj)
  }

  //request otcs api method, the otcs api is cover by sss backend
  const uploadCertService = async (option, fileName, partName, setField, uploadObj) => {
    const { folderId, uniqfileName, document, expiryDate, suvc, section, sectionAlt, certType } = uploadObj
    console.log({ folderId, uniqfileName, document, expiryDate, suvc, section, sectionAlt, certType })
    const bodyFormData = new FormData();
    bodyFormData.append('folderId', folderId);
    bodyFormData.append('fileName', uniqfileName);
    bodyFormData.append('document', document);
    bodyFormData.append('expiryDate', expiryDate);
    bodyFormData.append('suvc', suvc);
    bodyFormData.append('section', section);
    bodyFormData.append('sectionAlt', sectionAlt);
    bodyFormData.append('certType', certType);

    if(!fetchParam.folderId){
      setneedUpdateFolderIdLoading(true)
      message.warning('Don`t refresh page, Or you will cause unknown error with upload',5);
    }
    const uploadResponse = await fileUploadToOTCSRequest('/file/upload', bodyFormData);
    if (uploadResponse && uploadResponse.data && uploadResponse.data.results && uploadResponse.data.results.data &&
      uploadResponse.data.results.data.properties && uploadResponse.data.results.data.properties.id) {
      const documentId = uploadResponse.data.results.data.properties.id + '';
      const parentId = uploadResponse.data.results.data.properties.parent_id + '';
      //if missing folderid need to create one
      if (!fetchParam.folderId) {
        dispatch(deiActions.updateFolderId({ suvc: fetchParam.suvc, parentId: parentId }));
      }
      option.onSuccess('')
      let tempStatusObject = option.file
      tempStatusObject.status = 'done'
      tempStatusObject.documentId = `${fileName}_${documentId}`
      console.log('setField', setField)
      form.setFieldValue(setField, [tempStatusObject])
    } else {
      setneedUpdateFolderIdLoading(false)
      option.onError('upload error')
      message.error(`Upload failed, Please try again`, 5)
    }
  }

  let templateUploadProps = {
    disabled: hasSearchPermissions,
    listType: 'text',
    name: 'file',
    multiple: false,
    action: '',
    showUploadList: {
      showDownloadIcon: true,
      showRemoveIcon: true,
    },
    onDownload: file => {
      console.log('file', file)
      if (!file.originFileObj) {
        dispatch(deiActions.downloadFile(file.documentId.split('_').pop(), file.name));
      }
    }
  }


  const MBEUploadProps = {
    ...templateUploadProps,
    beforeUpload(file) {
      console.log(file)
      if (file.size >= (8 * 1024 * 1024)) {
        message.error('File too large. 8MB max.');
        return false || Upload.LIST_IGNORE;
      }
      if(file.name.split('.')[0].length>55){
        message.error('File name too long. Limit to 55 characters include space.');
        return false || Upload.LIST_IGNORE;
      }
      if (!form.getFieldValue('MBEExpDate')) {
        message.error(`Please Select Expiration Date First`, 5);
        return false || Upload.LIST_IGNORE;
      }
    
    },
    customRequest(option) {
      handleUpload(option, 'MBE')
    },

    onChange(info) {
      console.log('info', info)
      if (info.file.status === 'removed') {
        form.setFieldValue('MBETempCert', [])
        form.setFieldValue('MBECert', [])
      }
    }

  }
  const MBEUploadSelfProps = {
    ...templateUploadProps,
    beforeUpload(file) {
      if (file.size >= (8 * 1024 * 1024)) {
        message.error('File too large. 8MB max.');
        return false || Upload.LIST_IGNORE;
      }
      if(file.name.split('.')[0].length>55){
        message.error('File name too long. Limit to 55 characters include space.');
        return false || Upload.LIST_IGNORE;
      }
    },
    customRequest(option) {
      handleUpload(option, 'MBESelf')
    },

    onChange(info) {
      console.log('info', info)
      if (info.file.status === 'removed') {
        form.setFieldValue('MBESelfTempCert', [])
        form.setFieldValue('MBESelfCert', [])
      }
    }

  }


  const WBEUploadProps = {
    ...templateUploadProps,
    beforeUpload(file) {
       if (file.size >= (8 * 1024 * 1024)) {
        message.error('File too large. 8MB max.');
        return false || Upload.LIST_IGNORE;
      }
      if(file.name.split('.')[0].length>55){
        message.error('File name too long. Limit to 55 characters include space.');
        return false || Upload.LIST_IGNORE;
      }
      if (!form.getFieldValue('WBEExpDate')) {
        message.error(`Please Select Expiration Date First`, 5);
        return false;
      }
    },
    customRequest(option) {
      handleUpload(option, 'WBE')
    },

    onChange(info) {
      console.log('info', info)
      if (info.file.status === 'removed') {
        form.setFieldValue('WBETempCert', [])
      }
    }
  }
  const WBEUploadSelfProps = {
    ...templateUploadProps,
    beforeUpload(file) {
      if (file.size >= (8 * 1024 * 1024)) {
       message.error('File too large. 8MB max.');
       return false || Upload.LIST_IGNORE;
     }
     if(file.name.split('.')[0].length>55){
      message.error('File name too long. Limit to 55 characters include space.');
      return false || Upload.LIST_IGNORE;
    }
   
   },
    customRequest(option) {
      handleUpload(option, 'WBESelf')
    },

    onChange(info) {
      console.log('info', info)
      if (info.file.status === 'removed') {
        form.setFieldValue('WBESelfTempCert', [])
      }
    }
  }


  const cancel = () => {
    form.resetFields();
    dispatch(deiActions.clearFormData())
    setShowDetail(false);
  };


  const onFinish = (values) => {

    console.log('Received values of form: ', values);

    //extract the documentID combine the upload list for dei_identifier array
    let uploadList = []
    if (form.getFieldValue('MBETempCert') && form.getFieldValue('MBETempCert').length > 0) {
      uploadList.push(form.getFieldValue('MBETempCert')[0].documentId)

    }
    if (form.getFieldValue('MBESelfTempCert') && form.getFieldValue('MBESelfTempCert').length > 0) {
      uploadList.push(form.getFieldValue('MBESelfTempCert')[0].documentId)

    }
    if (form.getFieldValue('WBETempCert') && form.getFieldValue('WBETempCert').length > 0) {
      uploadList.push(form.getFieldValue('WBETempCert')[0].documentId)

    }
    if (form.getFieldValue('WBESelfTempCert') && form.getFieldValue('WBESelfTempCert').length > 0) {
      uploadList.push(form.getFieldValue('WBESelfTempCert')[0].documentId)

    }
    if (values.federalArr && values.federalArr.length > 0) {
      values.federalArr.map(res => {
        if (res.FederalTempCert && res.FederalTempCert.length > 0) {
          uploadList.push(res.FederalTempCert[0].documentId)
        }
        if (res.FederalSelfTempCert && res.FederalSelfTempCert.length > 0) {
          uploadList.push(res.FederalSelfTempCert[0].documentId)
        }
      })
    }
    console.log('uploadList', uploadList)
    values.dei_identifier = uploadList

    //mandatory field to patch step api
    values.vendor_name = fetchParam.vendor_name

    //for change wbe/mbe council flag
    values.oldData = infoFromStep
    dispatch(deiActions.saveInfoToStep(suvcDetail.suvc, values, history))

    //logic for MVP3 start
    // if (isFetchStep) {
    //   dispatch(deiActions.saveInfoToStep(suvcDetail.suvc,values, history))
    // } else {
    //   // console.log(fileList)
    //   //
    //   const uploadList = []
    //   //operation upload module,add reservation_id to every fileObj and 
    //   //save this and originFileObj into uploadList ,then as params to operation uploadS3 API
    //   console.log('mbetempCert', form.getFieldValue('MBETempCert'))
    //   if (values.MBECertType === 'THIRD PARTY CERTIFICATION') {
    //     values.MBEExpDateTemp = values.MBEExpDate ? moment(values.MBEExpDate).format() : null
    //     values.MBECert = {
    //       fileList: form.getFieldValue('MBETempCert')
    //     }
    //     if (values.MBECert && values.MBECert.fileList && values.MBECert.fileList.length > 0) {
    //       values.MBECert.fileList.map(res => {
    //         if (res.originFileObj) {
    //           uploadList.push({ reservation_id: res.reservation_id, originFileObj: res.originFileObj })
    //         }
    //       })
    //     }
    //   } else if (values.MBECertType === 'SELF-SELECTED') {
    //     values.MBESelfCert = {
    //       fileList: form.getFieldValue('MBESelfTempCert')
    //     }
    //     if (values.MBESelfCert && values.MBESelfCert.fileList && values.MBESelfCert.fileList.length > 0) {
    //       values.MBESelfCert.fileList.map(res => {
    //         if (res.originFileObj) {
    //           uploadList.push({ reservation_id: res.reservation_id, originFileObj: res.originFileObj })
    //         }
    //       })
    //     }
    //   }

    //   if (values.WBECertType === 'THIRD PARTY CERTIFICATION') {
    //     values.WBEExpDateTemp = values.WBEExpDate ? moment(values.WBEExpDate).format() : null
    //     values.WBECert = {
    //       fileList: form.getFieldValue('WBETempCert')
    //     }
    //     if (values.WBECert && values.WBECert.fileList && values.WBECert.fileList.length > 0) {
    //       values.WBECert.fileList.map(res => {
    //         if (res.originFileObj) {
    //           uploadList.push({ reservation_id: res.reservation_id, originFileObj: res.originFileObj })
    //         }
    //       })
    //     }
    //   } else if (values.WBECertType === 'SELF-SELECTED') {
    //     values.WBESelfCert = {
    //       fileList: form.getFieldValue('WBESelfTempCert')
    //     }
    //     if (values.WBESelfCert && values.WBESelfCert.fileList && values.WBESelfCert.fileList.length > 0) {
    //       values.WBESelfCert.fileList.map(res => {
    //         if (res.originFileObj) {
    //           uploadList.push({ reservation_id: res.reservation_id, originFileObj: res.originFileObj })

    //         }
    //       })
    //     }
    //   }

    //   if (values.federalArr && values.federalArr.length > 0) {
    //     values.federalArr.map(res => {

    //       if (res.FederalCertType === "THIRD PARTY CERTIFICATION") {
    //         res.FederalExpDateTemp = res.FederalExpDate ? moment(res.FederalExpDate).format() : null
    //         res.FederalCert = {
    //           fileList: res.FederalTempCert
    //         }
    //         if (res.FederalCert && res.FederalCert.fileList && res.FederalCert.fileList.length > 0) {
    //           res.FederalCert.fileList.map(fres => {
    //             if (fres.originFileObj) {
    //               uploadList.push({ reservation_id: fres.reservation_id, originFileObj: fres.originFileObj })

    //             }
    //           })
    //         }
    //       } else if (res.FederalCertType === "SELF-SELECTED") {
    //         res.FederalSelfCert = {
    //           fileList: res.FederalSelfTempCert
    //         }
    //         if (res.FederalSelfCert && res.FederalSelfCert.fileList && res.FederalSelfCert.fileList.length > 0) {
    //           res.FederalSelfCert.fileList.map(fres => {
    //             if (fres.originFileObj) {
    //               uploadList.push({ reservation_id: fres.reservation_id, originFileObj: fres.originFileObj })
    //             }
    //           })
    //         }
    //       }
    //     })
    //   }



    //   values.suvc = suvcDetail.suvc
    //   values.contactId = contactId
    //   console.log('values.federalArr', values.federalArr)
    //   console.log("uploadList", uploadList)
    //   if (uploadList.length > 0) {
    //     dispatch(deiActions.oprationCertification(suvcDetail.suvc, suvcDetail.contactId, uploadList, values, history))
    //   } else {
    //     dispatch(deiActions.saveInfoToSSSInner(values, history))
    //   }
    // }
    //logic for MVP3 end
  };

  //clear certification file when MBE change diverseclassfication/expData  WBE change expData and MBE/WBE cert type changed
  const onValuesChange = (changedValues, values) => {
    console.log('%c changedValues ⧭', 'color: #ffa280', changedValues);
    console.log('infoFromStep', infoFromStep)
    if(Object.keys(changedValues)[0] === 'MBE_DiverseSupplier'){

      form.setFieldsValue({
        'MBESelfTempCert': [],
        'MBESelfCert': [],
        'MBETempCert': [],
        'MBECert': [],
        'MBEExpDate': null
      })
    }
    
    if (Object.keys(changedValues)[0] === 'MBE_DiverseClassfication' && infoFromStep.MBE_DiverseClassfication && changedValues.MBE_DiverseClassfication !== infoFromStep.MBE_DiverseClassfication) {
      form.setFieldsValue({
        'MBESelfTempCert': [],
        'MBESelfCert': [],
        'MBETempCert': [],
        'MBECert': [],
        'MBEExpDate': null
      })
    }

    if (Object.keys(changedValues)[0] === 'MBEExpDate' && infoFromStep.MBEExpDate && moment(infoFromStep.MBEExpDate) && moment(changedValues.MBEExpDate).format('YYYY-MM-DD') !== moment(infoFromStep.MBEExpDate).format('YYYY-MM-DD')) {
      form.setFieldsValue({
        'MBESelfTempCert': [],
        'MBESelfCert': [],
        'MBETempCert': [],
        'MBECert': [],
      })
    }

    if(Object.keys(changedValues)[0] === 'WBE_WomenOwnedSupplier'){
      form.setFieldsValue({
        'WBESelfTempCert': [],
        'WBESelfCert': [],
        'WBETempCert': [],
        'WBECert': [],
      })
    }
    if (Object.keys(changedValues)[0] === 'WBEExpDate' && infoFromStep.WBEExpDate && moment(infoFromStep.WBEExpDate) && moment(changedValues.WBEExpDate).format('YYYY-MM-DD') !== moment(infoFromStep.WBEExpDate).format('YYYY-MM-DD')) {
      form.setFieldsValue({
        'WBESelfTempCert': [],
        'WBESelfCert': [],
        'WBETempCert': [],
        'WBECert': [],
      })
    }


    if (Object.keys(changedValues)[0] === 'MBECertType') {
      form.setFieldsValue({
        'MBESelfTempCert': [],
        'MBESelfCert': [],
        'MBETempCert': [],
        'MBECert': [],
        'MBEExpDate': null
      })
    }
    if (Object.keys(changedValues)[0] === 'WBECertType') {
      form.setFieldsValue({
        'WBESelfTempCert': [],
        'WBESelfCert': [],
        'WBETempCert': [],
        'WBECert': [],
        'WBEExpDate': null
      })
    }


  }

  // Can not select days before today and today for all exp date plugin
  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  return (
    <div className="detail-wrapper" style={{ display: isShow ? 'block' : 'none' }}>
      <div className="title">{t('dei.title')}</div>

      <span className="section-line-height">{t('dei.section1')}</span>
      <br></br>
      <br></br>
      <span className="section-line-height">{t('dei.section2-1')}</span>
      <br></br>
      <br></br>
      <span className="section-line-height"><b>·</b>{t('dei.section2-2')}</span>
      <br></br>
      <br></br>
      <span className="section-line-height">{t('dei.section2-3')} <a href="mailto:diversity.supplier@corp.sysco.com" title="mailto:diversity.supplier@corp.sysco.com" >diversity.supplier@corp.sysco.com</a></span>
      <br></br>
      <br></br>
      <br></br>
      <b>Links:</b>
      <br></br>
      <span className="section-line-height">Supplier Diversity 2022 DEI Report -
    <a
          className="hyper-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sysco.com/Suppliers/Supplier-Partnerships/Supplier-Diversity.html"
        >
          Diversity-and-Inclusion
      </a>
      </span>
      <br></br>

      <span className="section-line-height">·English -  <a
        className="hyper-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.sysco.com/dam/Sysco/DEI/SYY-006_2022-DEI-Report_v23-ADA-11.16.22.pdf"
      >SYY-006_2022-DEI-Report_v23-ADA-11.16.22</a></span>
      <br></br>

      <span className="section-line-height">·Español Latino American -  <a
        className="hyper-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.sysco.com/dam/Sysco/About/Diversity-And-Inclusion/SYY-006_2022-DE-I-Report_v23-ada_ESLA.pdf"
      >SYY-006_2022-DE-I-Report_v23-ada_ESLA</a></span>
      <br></br>

      <span className="section-line-height">·Français du Canada -  <a
        className="hyper-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.sysco.com/dam/Sysco/About/Diversity-And-Inclusion/SYY-006_2022-DE-I-Report_v23-ada_FRCA.pdf"
      >SYY-006_2022-DE-I-Report_v23-ada_FRCA</a></span>


      <Spin tip="Loading..." spinning={fetchSSSLoading ||needUpdateFolderIdLoading}>
        <Form form={form} className="detail-info" disabled={hasSearchPermissions} style={{ marginBottom: '10px', marginTop: '40px' }} labelAlign={'left'} onFinish={onFinish} onValuesChange={onValuesChange} >
          <Form.Item name="mainSelect" label="Do you want to add diversity information:" rules={[{ required: true, message: 'Please select one!' }]}>
            <Select placeholder="Select one" allowClear={true} style={{ width: '180px' }} >
              <Option value="Y">Yes</Option>
              <Option value="N">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.mainSelect !== currentValues.mainSelect}
          >
            {({ getFieldValue }) => {
              return (
                getFieldValue('mainSelect') === 'Y' ? (

                  <div>
                    <div className="detail-title">Diverse information</div>
                    <Row gutter={20}>
                      <Col>
                        <Form.Item name="MBE_DiverseSupplier" label="Diverse Supplier(MBE) :" rules={[{ required: true, message: 'Please select one!' }]} >
                          <Select placeholder="Select one" allowClear={true} style={{ minWidth: '150px' }} >
                            <Option value="Y">Yes</Option>
                            <Option value="N">No</Option>
                            <Option value="D">I choose not to disclose</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.MBE_DiverseSupplier !== currentValues.MBE_DiverseSupplier}
                      >
                        {({ getFieldValue }) => {
                          return (
                            getFieldValue('MBE_DiverseSupplier') === 'Y' ? (
                              <Col>
                                <Form.Item name="MBE_DiverseClassfication" label="Diverse Classfication" rules={[{ required: true, message: 'Please select one!' }]}>
                                  <Select placeholder="Select one" allowClear={true} style={{ minWidth: '350px' }}>
                                    {optionLists['optionsArray']['DiverseClassficationOptions'].map(item => (
                                      <Option key={item.value} value={item.value}>
                                        {item.text}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            ) : null
                          )
                        }}
                      </Form.Item>
                    </Row>
                    <Form.Item
                      noStyle
                      shouldUpdate
                    >
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('MBE_DiverseSupplier') === 'Y' && getFieldValue('MBE_DiverseClassfication') ? (
                            <div>
                              <Row gutter={20}>
                                <Col>
                                  <Form.Item label="Certification (MBE)" name="MBECertType" rules={[{ required: true, message: 'Please select one!' }]}>
                                    <Select placeholder="Select one" allowClear={true} style={{ minWidth: '150px' }} onChange={()=>{form.validateFields(['MBECert','MBEExpDate'])}}>
                                      <Option value="THIRD PARTY CERTIFICATION">Third-party</Option>
                                      <Option value="SELF-SELECTED">Self-selected</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col>

                                  <Form.Item label="" name="MBECert" rules={[{ required: getFieldValue('MBECertType') === 'THIRD PARTY CERTIFICATION' && getFieldValue('MBETempCert').length === 0, message: 'Please Upload File!' }]}>
                                    <Upload {...MBEUploadProps} fileList={getFieldValue('MBETempCert') || []} >
                                      <Button icon={<UploadOutlined />} disabled={getFieldValue('MBECertType') !== 'THIRD PARTY CERTIFICATION' || hasSearchPermissions}>Click to upload</Button>
                                    </Upload>
                                  </Form.Item>
                                </Col>
                              <Col>
                                <Form.Item label="Expiration Date of Certification (MBE)" name="MBEExpDate" rules={[{ required: getFieldValue('MBECertType') === 'THIRD PARTY CERTIFICATION'&& getFieldValue('MBETempCert').length === 0, message: 'Please Select Expiration Date!' }]}>
                                  <DatePicker disabledDate={disabledDate} disabled={getFieldValue('MBECertType') !== 'THIRD PARTY CERTIFICATION' || hasSearchPermissions} />
                                </Form.Item>
                              </Col>
                              </Row>
                            <Form.Item
                              noStyle
                              shouldUpdate
                            >
                              {({ getFieldValue }) => {
                                return (
                                  getFieldValue('MBECertType') === 'SELF-SELECTED' ? (
                                    <Row gutter={20}>
                                      <Col>
                                        <Form.Item label="Self-classified">
                                          <a
                                            className="hyper-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="/publicresources/pdf/Supplier%20Diversity%20Vendor%20Profile%20Form.pdf"
                                          >
                                            Download self-classified document
                                          </a>
                                        </Form.Item>

                                      </Col>
                                      <Col>
                                        <Form.Item label="Upload self-classified document" name="MBESelfCert" rules={[{ required: getFieldValue('MBECertType') === 'SELF-SELECTED' &&  getFieldValue('MBESelfTempCert').length === 0, message: 'Please Upload File!' }]}>

                                          <Upload {...MBEUploadSelfProps} fileList={getFieldValue('MBESelfTempCert') || []}>
                                            <Button icon={<UploadOutlined />} disabled={hasSearchPermissions}>Click to upload</Button>
                                          </Upload>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  ) : null
                                )
                              }}
                            </Form.Item>
                            </div>
                          ) : null
                        )
                      }}
                    </Form.Item>

                  <div className="detail-title">Women Owned Supplier (WBE)</div>

                  <Row gutter={20}>
                    <Col>
                      <Form.Item name="WBE_WomenOwnedSupplier" label="Women Owned Supplier (WBE)" rules={[{ required: true, message: 'Please select one!' }]}>
                        <Select placeholder="Select one" allowClear={true} style={{ minWidth: '150px' }}>
                          <Option value="Y">Yes</Option>
                          <Option value="N">No</Option>
                          <Option value="D">I choose not to disclose</Option>

                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    noStyle
                    shouldUpdate
                  >
                    {({ getFieldValue }) => {
                      return (
                        getFieldValue('WBE_WomenOwnedSupplier') && getFieldValue('WBE_WomenOwnedSupplier') === 'Y' ? (
                          <div>
                            <Row gutter={20}>
                              <Col>
                                <Form.Item label="Certification (WBE)" name="WBECertType" rules={[{ required: true, message: 'Please select one!' }]}>
                                  <Select placeholder="Select one" allowClear={true} style={{ minWidth: '150px' }} onChange={()=>{form.validateFields(['WBECert','WBEExpDate'])}}>
                                    <Option value="THIRD PARTY CERTIFICATION">Third-party</Option>
                                    <Option value="SELF-SELECTED">Self-selected</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item label="" name="WBECert" rules={[{ required: getFieldValue('WBECertType') === 'THIRD PARTY CERTIFICATION' && getFieldValue('WBETempCert').length === 0, message: 'Please Upload File!' }]}>
                                  <Upload {...WBEUploadProps} fileList={getFieldValue('WBETempCert') || []}>
                                    <Button icon={<UploadOutlined />} disabled={getFieldValue('WBECertType') !== 'THIRD PARTY CERTIFICATION' || hasSearchPermissions}>Click to upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>

                              <Col>
                                <Form.Item label="Expiration Date of Certification (WBE)" name="WBEExpDate" rules={[{ required: getFieldValue('WBECertType') === 'THIRD PARTY CERTIFICATION'&& getFieldValue('WBETempCert').length === 0, message: 'Please Select Expiration Date!' }]}>
                                  <DatePicker disabledDate={disabledDate} disabled={getFieldValue('WBECertType') !== 'THIRD PARTY CERTIFICATION' || hasSearchPermissions} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.Item
                              noStyle
                              shouldUpdate
                            >
                              {({ getFieldValue }) => {
                                return (
                                  getFieldValue('WBECertType') === 'SELF-SELECTED' ? (
                                    <Row gutter={20}>
                                      <Col>
                                        <Form.Item label="Self-classified" >

                                          <a
                                            className="hyper-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="/publicresources/pdf/Supplier%20Diversity%20Vendor%20Profile%20Form.pdf"
                                          >
                                            Download self-classified document
                                          </a>
                                        </Form.Item>
                                      </Col>
                                      <Col>
                                        <Form.Item label="Upload self-classified document" name="WBESelfCert" rules={[{ required: getFieldValue('WBECertType') === 'SELF-SELECTED' && getFieldValue('WBESelfTempCert').length === 0, message: 'Please Upload File!' }]}>
                                          <Upload {...WBEUploadSelfProps} fileList={getFieldValue('WBESelfTempCert') || []}>
                                            <Button icon={<UploadOutlined />} disabled={hasSearchPermissions}>Click to upload</Button>
                                          </Upload>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  ) : null
                                )
                              }}
                            </Form.Item>
                          </div>
                        ) : null
                      )
                    }}
                  </Form.Item>
                  <div className="detail-title">Federal Status</div>
                  <Form.List name="federalArr">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Space key={field.key} align="baseline" >

                            <div>
                              <Row gutter={20}>
                                <Form.Item
                                  noStyle
                                  shouldUpdate>
                                  {({ getFieldsValue, setFieldValue }) => {
                                    return (
                                      <Col>
                                        <Form.Item

                                          {...field}
                                          label="Federal Status"
                                          name={[field.name, 'FederalStatusCode']}
                                          rules={[{ required: true, message: 'Please select one!' }]}>
                                          <Select placeholder="Select one" allowClear={true} style={{ minWidth: '150px' }}
                                            onDropdownVisibleChange={() => {
                                              console.log(getFieldsValue(["federalArr", field.name, "FederalStatusCode"]).federalArr)
                                              setoldItem(getFieldsValue(["federalArr", field.name, "FederalStatusCode"]).federalArr)
                                            }}
                                            onSelect={(value) => {


                                              console.log('oldItem', oldItem)
                                              let similayItem = []
                                              similayItem = oldItem.filter(res => {
                                                if (res) {
                                                  return res.FederalStatusCode === value
                                                }
                                              })
                                              if (similayItem.length > 0) {
                                                setFieldValue(["federalArr", field.name, "FederalStatusCode"], '')
                                                message.error(`Federal_Status: ${value} is exist, please choose another option`, 8);
                                              }
                                              setFieldValue(["federalArr", field.name, "FederalTempCert"], [])
                                              setFieldValue(["federalArr", field.name, "FederalCert"], [])
                                              setFieldValue(["federalArr", field.name, "FederalSelfTempCert"], [])
                                              setFieldValue(["federalArr", field.name, "FederalSelfCert"], [])
                                              setFieldValue(["federalArr", field.name, "FederalExpDate"], null)
                                            }}>
                                            {optionLists['optionsArray']['FederalStatusCodeOptions'].map(item => (
                                              <Option key={item.value} value={item.value}>
                                                {item.text}
                                              </Option>

                                            ))}
                                            <Option value="I Choose Not To Disclose">I choose not to disclose</Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                    )
                                  }}
                                </Form.Item>
                                <Col>

                                  <Form.Item
                                    {...field}
                                    label="Small Business"
                                    name={[field.name, 'SmallBusiness']}
                                    rules={[{ required: true, message: 'Please select one!' }]}>
                                    <Select placeholder="Select one" allowClear={true} style={{ minWidth: '150px' }} >
                                      <Option value="Y">Yes</Option>
                                      <Option value="N">No</Option>
                                      <Option value="D">I choose not to disclose</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Form.Item
                                noStyle
                                shouldUpdate
                              >

                                {({ getFieldValue, setFieldValue }) => {
                                  return (
                                    getFieldValue(["federalArr", field.name, "FederalStatusCode"]) && getFieldValue(["federalArr", field.name, "FederalStatusCode"]) !== "I Choose Not To Disclose" ? (
                                      <div>
                                        <Row gutter={20}>

                                          <Col>
                                            <Form.Item
                                              {...field}
                                              label="Certification (Federal Status)"
                                              rules={[{ required: true, message: 'Please select one!' }]}
                                              name={[field.name, 'FederalCertType']}
                                            >
                                              <Select placeholder="Select one" allowClear={true} style={{ minWidth: '150px' }} onChange={(value) => {
                                                console.log(value)
                                                setFieldValue(["federalArr", field.name, "FederalTempCert"], [])
                                                setFieldValue(["federalArr", field.name, "FederalCert"], [])
                                                setFieldValue(["federalArr", field.name, "FederalSelfTempCert"], [])
                                                setFieldValue(["federalArr", field.name, "FederalSelfCert"], [])
                                                setFieldValue(["federalArr", field.name, "FederalExpDate"], null)

                                                form.validateFields([["federalArr", field.name, "FederalCert"],["federalArr", field.name, "FederalExpDate"]])

                                              }}>
                                                <Option value="THIRD PARTY CERTIFICATION">Third-party</Option>
                                                <Option value="SELF-SELECTED">Self-selected</Option>
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                          <Col>
                                            <Form.Item
                                              {...field}
                                              rules={[
                                                {
                                                  required: getFieldValue(["federalArr", field.name, 'FederalCertType']) === "THIRD PARTY CERTIFICATION"  &&getFieldValue(["federalArr", field.name, "FederalTempCert"]).length === 0,
                                                  message: 'Please Upload File!',
                                                },
                                              ]}
                                              label=''
                                              name={[field.name, 'FederalCert']}
                                            >
                                              <Upload
                                                disabled={hasSearchPermissions}
                                                listType='text'
                                                name='file'
                                                multiple={false}
                                                beforeUpload={(file) => {
                                                  if (file.size >= (8 * 1024 * 1024)) {
                                                    message.error('File too large. 8MB max.');
                                                    return false || Upload.LIST_IGNORE;
                                                  }
                                                  if(file.name.split('.')[0].length>55){
                                                    message.error('File name too long. Limit to 55 characters include space.');
                                                    return false || Upload.LIST_IGNORE;
                                                  }
                                                  if (!getFieldValue(["federalArr", field.name, 'FederalExpDate'])) {
                                                    message.error(`Please Select Expiration Date First`, 5);
                                                    return false|| Upload.LIST_IGNORE;
                                                  }
                                                }}
                                                customRequest={(option) => {
                                                  if(option.file.name.indexOf('_')>0){
                                                    option.file= new File([option.file],option.file.name.replaceAll('_',' ')) 
                                                  }
                                                  const mockItem = {
                                                    uid: '1',
                                                    name: option.file.name,
                                                    status: 'uploading',
                                                    url: '',
                                                    percent: 99,
                                                  };


                                                  let fileName = transformCertName(fetchParam.suvc, 'FED', getFieldValue(["federalArr", field.name, "FederalStatusCode"]), getFieldValue(["federalArr", field.name, "FederalCertType"]), option.file.name, getFieldValue(["federalArr", field.name, 'FederalExpDate']))
                                                  setFieldValue(["federalArr", field.name, "FederalTempCert"], [mockItem])
                                                  let originUploadObj = {
                                                    folderId: fetchParam.folderId ? fetchParam.folderId + '' : '',
                                                    uniqfileName: `${getFileNameWithoutExtension(option.file.name)}_${getUniqueId()}`,
                                                    document: option.file,
                                                    suvc: fetchParam.suvc,
                                                    section: 'FED',
                                                    sectionAlt: FederalTypeMap[getFieldValue(["federalArr", field.name, "FederalStatusCode"])],
                                                    certType: CertTypeMap[getFieldValue(["federalArr", field.name, "FederalCertType"])],
                                                    expiryDate: moment(getFieldValue(["federalArr", field.name, 'FederalExpDate'])).format('YYYY-MM-DD')
                                                  }
                                                  uploadCertService(option, fileName, 'FED', ["federalArr", field.name, "FederalTempCert"], originUploadObj)

                                                }}
                                                onChange={(info) => {
                                                  console.log('info', info)
                                                  if (info.file.status === 'removed') {
                                                    setFieldValue(["federalArr", field.name, "FederalTempCert"], [])
                                                    setFieldValue(["federalArr", field.name, "FederalCert"], [])
                                                  }
                                                }}
                                                showUploadList={{
                                                  showDownloadIcon: true,
                                                  showRemoveIcon: true,
                                                }}
                                                onDownload={(file) => {
                                                  console.log('file', file)
                                                  if (!file.originFileObj) {
                                                    dispatch(deiActions.downloadFile(file.documentId.split('_').pop(), file.name));
                                                  }
                                                }}
                                                fileList={getFieldValue(["federalArr", field.name, "FederalTempCert"]) || []}>
                                                <Button icon={<UploadOutlined />} disabled={getFieldValue(["federalArr", field.name, "FederalCertType"]) !== 'THIRD PARTY CERTIFICATION' || hasSearchPermissions}>Click to upload</Button>
                                              </Upload>
                                            </Form.Item>
                                          </Col>

                                          <Col>
                                            <Form.Item {...field}
                                              rules={[
                                                {
                                                  required: getFieldValue(["federalArr", field.name, 'FederalCertType']) === "THIRD PARTY CERTIFICATION"&&getFieldValue(["federalArr", field.name, "FederalTempCert"]).length === 0,
                                                  message: 'Please Select Expiration Date!',
                                                },
                                              ]}
                                              label="Expiration Date of Certification (Federal Status)"
                                              name={[field.name, 'FederalExpDate']}>
                                              <DatePicker disabledDate={disabledDate} disabled={getFieldValue(["federalArr", field.name, "FederalCertType"]) !== 'THIRD PARTY CERTIFICATION' || hasSearchPermissions} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                          noStyle
                                          shouldUpdate
                                        >
                                          {({ getFieldValue }) => {
                                            return (
                                              getFieldValue(["federalArr", field.name, "FederalCertType"]) === "SELF-SELECTED" ? (
                                                <Row gutter={20}>
                                                  <Col>
                                                    <Form.Item {...field}
                                                      label="Self-classified"
                                                      name={[field.name, 'FederalSelfCertdownload']}>

                                                      <a
                                                        className="hyper-link"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="/publicresources/pdf/Supplier%20Diversity%20Vendor%20Profile%20Form.pdf"
                                                      >
                                                        Download self-classified document
                                                </a>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col>
                                                    <Form.Item {...field}
                                                      rules={[
                                                        {
                                                          required: getFieldValue(["federalArr", field.name, 'FederalCertType']) === "SELF-SELECTED"  &&getFieldValue(["federalArr", field.name, "FederalSelfTempCert"]).length === 0,
                                                          message: 'Please Upload File!',
                                                        },
                                                      ]}
                                                      label="Upload self-classified document"
                                                      name={[field.name, 'FederalSelfCert']}>
                                                      <Upload
                                                        disabled={hasSearchPermissions}
                                                        listType='text'
                                                        name='file'
                                                        multiple={false}
                                                        action=''
                                                        beforeUpload={(file) => {
                                                          if (file.size >= (8 * 1024 * 1024)) {
                                                            message.error('File too large. 8MB max.');
                                                            return false || Upload.LIST_IGNORE;
                                                          }
                                                          if(file.name.split('.')[0].length>55){
                                                            message.error('File name too long. Limit to 55 characters include space.');
                                                            return false || Upload.LIST_IGNORE;
                                                          }
                                                        }}
                                                        customRequest={(option) => {
                                                          if(option.file.name.indexOf('_')>0){
                                                            option.file= new File([option.file],option.file.name.replaceAll('_',' ')) 
                                                          }
                                                          const mockItem = {
                                                            uid: '1',
                                                            name: option.file.name,
                                                            status: 'uploading',
                                                            url: '',
                                                            percent: 99,
                                                          };

                                                          let fileName = transformCertName(fetchParam.suvc, 'FED', getFieldValue(["federalArr", field.name, "FederalStatusCode"]), getFieldValue(["federalArr", field.name, "FederalCertType"]), option.file.name)
                                                          setFieldValue(["federalArr", field.name, "FederalSelfTempCert"], [mockItem])
                                                          let originUploadObj = {
                                                            folderId: fetchParam.folderId ? fetchParam.folderId + '' : '',
                                                            uniqfileName: `${getFileNameWithoutExtension(option.file.name)}_${getUniqueId()}`,
                                                            document: option.file,
                                                            suvc: fetchParam.suvc,
                                                            section: 'FED',
                                                            sectionAlt: FederalTypeMap[getFieldValue(["federalArr", field.name, "FederalStatusCode"])],
                                                            certType: CertTypeMap[getFieldValue(["federalArr", field.name, "FederalCertType"])],
                                                            expiryDate: ''
                                                          }
                                                          uploadCertService(option, fileName, 'FED', ["federalArr", field.name, "FederalSelfTempCert"], originUploadObj)
                                                          console.log(fileName)

                                                        }}
                                                        onChange={(info) => {
                                                          console.log('info', info)
                                                          if (info.file.status === 'removed') {
                                                            setFieldValue(["federalArr", field.name, "FederalSelfTempCert"], [])
                                                            setFieldValue(["federalArr", field.name, "FederalSelfCert"], [])
                                                          }
                                                        }}
                                                        showUploadList={{
                                                          showDownloadIcon: true,
                                                          showRemoveIcon: true,
                                                        }}
                                                        onDownload={(file) => {
                                                          console.log('file', file)
                                                          if (!file.originFileObj) {
                                                            dispatch(deiActions.downloadFile(file.documentId.split('_').pop(), file.name));
                                                          }
                                                        }}
                                                        fileList={getFieldValue(["federalArr", field.name, "FederalSelfTempCert"]) || []}>
                                                        <Button icon={<UploadOutlined />} disabled={hasSearchPermissions}>Click to upload</Button>
                                                      </Upload>
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                              ) : null)
                                          }}
                                        </Form.Item>
                                      </div>
                                    ) : null)
                                }}
                              </Form.Item>
                            </div>
                            {/* <MinusCircleOutlined onClick={() => remove(field.name)} disabled={hasSearchPermissions} /> */}
                          </Space>
                        ))}

                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Federal Status
                    </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  </div>
                ) : null
              )
            }}
          </Form.Item>
        {!hasSearchPermissions && (
          <div className="btn-footer">
            <Form.Item>
              <Button className="footer-btn btn-cancel" onClick={cancel} >
                CANCEL
                        </Button>
              <Button
                className={classNames('footer-btn btn-confirm', { 'btn-disable': hasSearchPermissions })}
                htmlType="submit"
              >
                SUBMIT FOR REVIEW
                        </Button>
            </Form.Item>
          </div>
        )}
          
        </Form>
      {hasSearchPermissions && (
        <div className="btn-footer">
          <Form.Item>
            <Button className="footer-btn btn-cancel" onClick={cancel} >
              CANCEL
                        </Button>
            <Button
              className={classNames('footer-btn btn-confirm', { 'btn-disable': hasSearchPermissions })}
              htmlType="submit"
            >
              SUBMIT FOR REVIEW
                        </Button>
          </Form.Item>
        </div>
      )}
      </Spin>
    </div >
  );
};

DetailInfo.propTypes = {
  t: PropTypes.func
};

export default DetailInfo;
