import moment from 'moment'
export const getOtmmBaseUrl = () => {
  // let url = process.env.REACT_APP_BFF
  //   return 'http://localhost:8093'
    let url = process.env.REACT_APP_BFF.replace(/\/dei$/, '').replace(/9005/, '8093');
    if (process.env.REACT_APP_BFF.match(/localhost/i)) {
      url = 'http://localhost:8093';
    }
    return url;
  };

export const checkUserHasSearchPermissions = permissions => permissions.includes('DEIAPP.SEARCH.SUPPLIERS');

export const getRandomRevertionId = () => {
  return  Date.now().toString(36) + Math.random().toString(36).substr(2);
}
 export const CertTypeMap = {
  'SELF-SELECTED':'SS',
  'THIRD PARTY CERTIFICATION':'TPC'
}
 export const DiverseTypeMap = {
  'AFRICAN AMERICAN (NOT HISPANIC)':'AANH',
  'AFRICAN/BLACK (NOT AMERICAN)':'ABNA',
  'ASIAN/PACIFIC ISLANDER (NOT AMERICAN)':'APIN',
  'ASIAN/PACIFIC ISLANDER AMERICAN':'APIA',
  'HISPANIC (NOT AMERICAN)':'HNA',
  'HISPANIC AMERICAN':'HA',
  'NATIVE/ALASKAN (NOT AMERICAN)':'NANA',
  'NATIVE/ALASKAN AMERICAN':'NAA',
  'OTHER':'OTH',
  'NON-MINORITY':'NM'
}
export  const FederalTypeMap = {
  '8(a) Business Development':'7',
  'Ability One':'8',
  'Alaska Native Corporations (ANC)':'14',
  'Community Development Corporations (CDC)':'22',
  'Disabled Owned Business Enterprise O(DOBE)':'19',
  'Disabled Veteran Business Enterprise (DVBE)':'20',
  'Disadvantage Business Enterprise (DBE)':'21',
  'Economically Disadvantage Women Owned Small Business (EDWOSB)':'16',
  'Hubzone':'2',
  'Indian Tribes, Native Hawaiian Organizations (NHO)':'13',
  'LGBTQ+ Business Enterprise (LGBTBE)':'18',
  'Service-Disabled Veteran Owned Small Business (SDVOSB)':'9',
  'Small Business Enterprise (SBE)':'1',
  'Small Disadvantage Business (SDB)':'6',
  'Veteran Owned Small Business (VOSB)':'3'
}
export const transformCertName = (suvc,sectionPart,attributePart,certTypePart,fileName,expDate) => {
  let certType = CertTypeMap[certTypePart]
  let tempFont = `${suvc}`
  switch(sectionPart){
    case 'MBE':
      if(certType === 'TPC'){
        tempFont = `${tempFont}_MBE_${DiverseTypeMap[attributePart]}_TPC_${moment(expDate).format('YYYY-MM-DD')}_${fileName}`
      }else{
        tempFont = `${tempFont}_MBE_${DiverseTypeMap[attributePart]}_SS_${fileName}`
      }
      break;
    case 'WBE':
      if(certType === 'TPC'){
        tempFont = `${tempFont}_WBE_TPC_${moment(expDate).format('YYYY-MM-DD')}_${fileName}`
      }else{
        tempFont = `${tempFont}_WBE_SS_${fileName}`
      }
      break
    case 'FED':
      if(certType === 'TPC'){
        tempFont = `${tempFont}_FED_${FederalTypeMap[attributePart]}_TPC_${moment(expDate).format('YYYY-MM-DD')}_${fileName}`
      }else{
        tempFont = `${tempFont}_FED_${FederalTypeMap[attributePart]}_SS_${fileName}`
      }
      break
    default:
      tempFont = ''
  }
  return tempFont
}


export const getFileNameWithoutExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return filename;
  }
  const filenameWithoutExtension = filename.substring(0, lastDotIndex);
  return filenameWithoutExtension;
}



export const getUniqueId = () => {
  const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
  return uniqueId;
};