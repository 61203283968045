import initialState from 'store/initialState';
import { FETCHSUBSUPPLIERS,RESELECTSUBVENDORS } from 'actions/actionTypes';

const subVendor = (state = initialState.subVendor, action) => {
  switch (action.type) {
    case FETCHSUBSUPPLIERS.REQUEST:
      return { ...state, fetchingSubVendor: true, error: null };
    case FETCHSUBSUPPLIERS.SUCCESS:
      return { ...state, fetchingSubVendor: false, data: action.payload };
    case FETCHSUBSUPPLIERS.FAILURE:
      return { ...state, fetchingSubVendor: false, data: [], error: action.payload };
    case RESELECTSUBVENDORS.REQUEST:
      return { ...state, fetchingSubVendor: false, data: [], error: null };
    default:
      return state;
  }
};

export default subVendor;
