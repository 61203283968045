import { notification, Modal } from 'antd';
import React from 'react';

notification.config({
  placement: 'bottomRight'
});

const openNotification = payload => {
  const { message, description, className } = payload;
  const key = `open${Date.now()}`;

  notification[className]({
    message,
    description,
    key,
    className
  });
};

const openSubmitSuccessInfo = payload => {

  Modal.info({
    content: (
      <div>

      <p>Thank you. Your registration/update has been received.</p>

      <p>If needed, please use this link to view our Operating Site locations:  </p>
      <p><a href="https://sysco.com/Contact/Contact/Our-Locations.html" target="_blank" rel="noopener noreferrer" style={{color: "#008ace"}}>https://sysco.com/Contact/Contact/Our-Locations.html</a></p>
      <br></br>
      <p>We place value on supplier relationships and are continually updating our capabilities and library of resources to help supplier partners be successful.</p>
      <br></br>
      <p>If needed, please use this link to view our supplier resources: </p>
      <p><a href="https://sysco.com/Suppliers/Supplier-Partnerships/Supplier-Resources.html" target="_blank" rel="noopener noreferrer" style={{color:" #008ace"}}>https://sysco.com/Suppliers/Supplier-Partnerships/Supplier-Resources.html</a></p>
      <br></br>
      <p>Sysco Supplier Diversity Team</p>
      <p><a href="mailto:Diversity.Supplier@corp.Sysco.com" title="mailto:Diversity.Supplier@corp.Sysco.com" >Diversity.Supplier@corp.Sysco.com</a></p>


    </div>
    ),
    onOk() {
      payload.history.push('/suite/dashboard');
    },
    width: '520px',
    okText: 'Close'
  });
};

export { openNotification, openSubmitSuccessInfo };
