import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { USER, FETCHCOMPANY, FETCHSUPPLIERS } from 'actions/actionTypes';
import { action } from 'reduxHelpers';
import { checkUserHasSearchPermissions } from '../../utils/util'

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getUserId = username => {
  if (username.includes(adPrefix)) {
    return username.split('@')[0].replace(adPrefix, '');
  }
  const oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

function* loadUserAsync({ userId }) {
  try {
    yield put({ type: FETCHCOMPANY.SUCCESS, payload: { suvc: null, company: null } });
    const response = yield getRequest(`/user/${getUserId(userId)}?app_name=DEI`);
    console.log('response11111', response)
    // if(response.data.permissions){}
    let hasPermission = checkUserHasSearchPermissions(response.data.permissions)
    if (hasPermission) {
      try {
        const { data } = yield getRequest(`/deiCombination/vendors`);
        data.map(res=>{
          res.taxId = res.suvc
        })
        yield put({ type: FETCHSUPPLIERS.SUCCESS, payload: {data} });
      } catch (error) {
        yield put({ type: FETCHSUPPLIERS.FAILURE, payload: error.message });
        yield put(
          action('SHOW_NOTIFICATION', {
            description: error.message,
            className: 'error',
            message: 'SUPPLIERS LOADING ERROR'
          })
        );
      }
    } else{
      yield put({ type: FETCHSUPPLIERS.FAILURE, payload: null });
    }
    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER LOADING ERROR'
      })
    );
  }
}

export default loadUserAsync;
