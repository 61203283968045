import { put } from 'redux-saga/effects';
import { getRequest} from '_http';
import { FETCHCOMPANY } from 'actions/actionTypes';
import { action } from 'reduxHelpers';


function* fetchCompanyAsync( {info} ) {
  try {
    //Melody Todo get DEI info by call step api
    console.log('getInFetchcompanyAsync',{info },info)
    if (info) {
      const response = yield getRequest(`/deiCombination/getInfoFromStep/${info.suvc}`,);
      // const response = yield getRequest(`/deiCombination/getInfoFromStep/1333333333333`,);
      console.log(response)
      // console.log('getInFetchcompanyAsync',suvc)
      // let temp = companyAttr.filter(item => item.suvc === suvc);

      if(response.status===200){
          let responseObj = {...response.data.companyDetail,taxId:info.taxId,companyName:response.data.companyDetail.vendor_name,suvc:info.suvc,deiInfo:response.data.stepForDeiData,folderId:info.folderId}
          console.log('responseObj',responseObj)
          if(responseObj.companyName){
            yield put({ type: FETCHCOMPANY.SUCCESS, payload: { suvc: info.suvc, company: responseObj } });
          }else{
            yield put({ type: FETCHCOMPANY.SUCCESS, payload: { suvc: null, company: null } });
            yield put(
              action('SHOW_NOTIFICATION', {
                description: `SUVC ${info.suvc} cannot find in STEP`,
                className: 'error',
                message: 'FETCH COMPANY ERROR'
              })
            );
          }
          
        }else {
          yield put({ type: FETCHCOMPANY.FAILURE, payload: response.data.message });
        }
    } else {
      yield put({ type: FETCHCOMPANY.SUCCESS, payload: { suvc: null, company: null } });
    }
  } catch (error) {
    yield put({ type: FETCHCOMPANY.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FETCH COMPANY ERROR'
      })
    );
  }
}

export default fetchCompanyAsync;
