import { put } from 'redux-saga/effects';
import { FileUploadRequest ,FileCombineRequest} from '_http';
import { CERTIFICATIONSOP,COMMITINFOTOSSSINNER, CERTIFICATIONTOOTCS } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

function* uploadCertificateAsync({ suvc, contactId,uploadList,info,history }) {
  try {
    let responseArr = []
    uploadList.map(res=>{
      const bodyFormData = new FormData();
      bodyFormData.append('file', res.originFileObj);
      bodyFormData.append('reservationId', res.reservation_id);
      bodyFormData.append('suvc', suvc);
      bodyFormData.append('contactId', contactId);  
      responseArr.push(FileUploadRequest(`/otmmstream/uploadcertification`, bodyFormData))
    })
    const uploadResponse = yield FileCombineRequest(responseArr);
    let errorarr= uploadResponse.filter(res=>{
      return res.status!==200
    })
    if (errorarr&& errorarr.length>0) {
      yield put({ type: CERTIFICATIONSOP.FAILURE, payload: errorarr[0].data.message });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: errorarr[0].data.message,
          className: 'error',
          message: errorarr[0].statusText
        })
      );
    } else {
      yield put({ type: CERTIFICATIONSOP.SUCCESS, payload: {status:'ok'} });
      console.log('submitObjinfo',{info})
      yield put(action(COMMITINFOTOSSSINNER.REQUEST,{info,history}))
    }
  } catch (error) {
    yield put({ type: CERTIFICATIONSOP.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.response.data.description,
        className: 'error',
        message: 'Upload Failed'
      })
    );
  }
}

function* uploadCertificationToOTCS({suvc, contactId,uploadFile,reservation_id}){
  try{
    const bodyFormData = new FormData();  
    bodyFormData.append('file', uploadFile);
    bodyFormData.append('reservationId', reservation_id);
    bodyFormData.append('suvc', suvc);
    bodyFormData.append('contactId', contactId);  

    const response = yield FileUploadRequest(`/otmmstream/uploadcertification`, bodyFormData)
    console.log('response))))))))))))))',response)
    if(response.status === '200'){
      yield put({ type: CERTIFICATIONTOOTCS.SUCCESS, payload: {data:{status:'ok'}} });
    }else{
      yield put({ type: CERTIFICATIONTOOTCS.FAILURE, payload: {data:{status:'failure'}} });
    }
  }catch(error){
    yield put({ type: CERTIFICATIONTOOTCS.FAILURE, payload:  {data:{status:'catch error'}} });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.response.data.description,
        className: 'error',
        message: 'Upload Failed'
      })
    );
  }
    
}



export {uploadCertificateAsync,uploadCertificationToOTCS}
