import { all } from 'redux-saga/effects';
import userSaga from './userSaga'
import companySaga from './companySaga';
import notificationSaga from './notificationSaga';
import optionSaga from './optionsSaga';
import certificationSaga from './certificationSaga'
import SSScombinationSaga from './deiCombinationSaga'

export default function* rootSaga() {
  yield all([...userSaga, ...companySaga, ...notificationSaga, ...optionSaga,...certificationSaga,...SSScombinationSaga]);
}
