import { put } from 'redux-saga/effects';
import { getRequest, putRequest ,fileDownloadFromOTCSRequest} from '_http';
import { COMMITINFOTOSSSINNER ,FETCHINFOFROMSSSINNER,COMMITINFOTOSTEP ,UPDATEFOLDERID,FETCHSUPPLIERS,FETCHSUBSUPPLIERS} from 'actions/actionTypes';
import { action } from 'reduxHelpers';



function* commitToSSSInner({info,history}) {
  console.log('fetchOptionAsync',{info})
  try {
    const response = yield putRequest(`/deiCombination/submit/putInSSSInner`,info);
    yield put({ type: COMMITINFOTOSSSINNER.SUCCESS, payload: response.data });
    yield put(action('SHOW_SURVEY_SUBMIT_SUCCESS_INFO', { history }));
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Save Data Successful',
        className: 'info',
        message: 'SUCCESS'
      })
    );
    
  } catch (error) {
    yield put({ type: COMMITINFOTOSSSINNER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COMMIT DATA ERROR'
      })
    );
  }
}

function* commitToStep({suvc,info,history}) {
  console.log('fetchOptionAsync',{info},{suvc})
  try {
    const response = yield putRequest(`/deiCombination/submit/putInStep/${suvc}`,info);
    yield put({ type: COMMITINFOTOSTEP.SUCCESS, payload: response.data });
    if(Object.keys(response.data).length>0){
      yield put(action('SHOW_SURVEY_SUBMIT_SUCCESS_INFO', { history }));
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Save Data Successful',
          className: 'info',
          message: 'SUCCESS'
        })
      );

    }else{
      yield put({ type: COMMITINFOTOSTEP.FAILURE, payload: null });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'submit error, please resubmit data or refresh page',
          className: 'error',
          message: 'COMMIT DATA ERROR'
        })
      );
    }
   
    
  } catch (error) {
    console.log(error)
    yield put({ type: COMMITINFOTOSTEP.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error+', please refresh page',
        className: 'error',
        message: 'COMMIT DATA ERROR'
      })
    );
  }
}


function* fetchInfoFromSSS({suvc}) {
  console.log('fetchOptionAsync',{suvc})
  try {
    const response = yield getRequest(`/deiCombination/getInfoFromSSSInner/${suvc}`,);
    yield put({ type: FETCHINFOFROMSSSINNER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: FETCHINFOFROMSSSINNER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FETCH DATA ERROR'
      })
    );
  }
}

function* clearFormData(){
  yield put({ type: FETCHINFOFROMSSSINNER.SUCCESS, payload: []});
}

function* updateFolderIdFunc({info}){
  try {
    const response = yield putRequest(`/deiCombination/updateFolderId`,info);
    console.log(response)
    if(response && response.data.folderId){
      let cacheVendor = JSON.parse(localStorage.getItem("vendor"))
      if(cacheVendor&& cacheVendor.subSuvcs){
        cacheVendor.subSuvcs.map(res=>{if(res.suvc === info.suvc){res.folderId = response.data.folderId}})
        localStorage.setItem('vendor',JSON.stringify(cacheVendor))
      }
    }
    yield put({ type: UPDATEFOLDERID.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATEFOLDERID.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Contact the administrator otherwise the file cannot be uploaded'+ error.message,
        className: 'error',
        message: 'Update FolderID Error'
      })
    );
  }
}

function* downloadFileAsync({ documentId, fileName }) {
  try {
    const downloadResp = yield fileDownloadFromOTCSRequest(`/file/fetch/${documentId}`);
    // const filename = downloadResp?.headers['content-disposition']
    const contentType = downloadResp?.headers['content-type']
    const url = window.URL.createObjectURL(new Blob([downloadResp?.data], { type: contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  } catch (error) {
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'DOWNLOAD FILE ERROR'
      })
    );
  }
}

function* fetchSuppliersAsync() {
  try {
    const { data } = yield getRequest(`/deiCombination/vendors`);
    yield put({ type: FETCHSUPPLIERS.SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: FETCHSUPPLIERS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SUPPLIERS LOADING ERROR'
      })
    );
  }
}

function* fetchSubSuppliersAsync({info}) {
  try {
    const { data } = yield getRequest(`/deiCombination/subVendors/${info.suvc}`);
    console.log(data)
    let tempArr = []
    if(data&& data.subSuvcs&& data.subSuvcs.length>0){
      data.subSuvcs.map(res=>{
        tempArr.push({...res,value: res.suvc, label: `${res.taxId} - ${res.name}` })

      })
    }
    yield put({ type: FETCHSUBSUPPLIERS.SUCCESS, payload: tempArr });
  } catch (error) {
    yield put({ type: FETCHSUBSUPPLIERS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SUPPLIERS LOADING ERROR'
      })
    );
  }
}


export {commitToSSSInner,fetchInfoFromSSS,clearFormData,commitToStep,updateFolderIdFunc,downloadFileAsync,fetchSuppliersAsync,fetchSubSuppliersAsync};
