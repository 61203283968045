import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button, Select, Spin } from 'antd';
import classNames from 'classnames';
import * as actions from 'actions';
import DetailInfo from 'components/DetailInfo';
import { checkUserHasSearchPermissions } from '../utils/util'



const Home = ({ t, history }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  // const loginSuvc = useSelector(state => _.get(state.user, 'data.suvc', null));
  const companyFetching = useSelector(state => _.get(state.selectedItem, 'fetching', false));
  const userFetching = useSelector(state => _.get(state.user, 'fetching', false));
  const userData = useSelector(state => _.get(state.user, 'data', false));
  const selectedCompany = useSelector(state => _.get(state.selectedItem, 'company', null));
  const selectedSuvc = useSelector(state => _.get(state.selectedItem, 'suvc', null));
  const [showDetail, setShowDetail] = useState(false);
  const [masterCompany, setmasterCompany] = useState({});
  const [subCompany, setsubCompany] = useState({});
  const [subSuvc,setsubSuvc] = useState(null);
  const optionLists = useSelector(state => _.get(state.options, 'data', null));
  const permissions = useSelector(state => _.get(state.user, 'data.permissions', []));
  const hasSearchPermissions = checkUserHasSearchPermissions(permissions);
  const master_vendor = useSelector(state => _.get(state.vendor, 'data', []));
  const sub_vendor = useSelector(state => _.get(state.subVendor, 'data', []));
  const sub_vendorfetching = useSelector(state => _.get(state.subVendor, 'fetchingSubVendor', false));

  console.log(hasSearchPermissions)
  let companyList = [];
  let vendor = {}

  if (hasSearchPermissions) {
    companyList = master_vendor.data.map(item => ({ value: item.suvc, label: item.taxId?`${item.taxId} - ${item.name}`:` ${item.name}` }));
  } else {
    vendor = localStorage.getItem('vendor') !== 'undefined' ? JSON.parse(localStorage.getItem('vendor')) : null
    if (vendor && vendor.subSuvcs && vendor.subSuvcs.length) {
      companyList = vendor.subSuvcs.map(item => ({ value: item.suvc, label: item.taxId?`${item.taxId} - ${item.name}`:` ${item.name}` }));
    }

  }

 
  const onMasterSelect = value =>{
    setsubSuvc(null)
    setsubCompany({})
    dispatch(actions.clearSubVendors());
    dispatch(actions.resetSelectedItem());
    
    console.log(value)
    let temp = master_vendor.data.filter(res => {
      return value === res.suvc
    })
    console.log(temp)
    
    setmasterCompany({ suvc: temp[0].suvc, taxId: temp[0].taxId, companyName: temp[0].name, folderId: temp[0].folderId })
    dispatch(actions.fetchSubSuppliers({suvc:value}))

  }
  const onSubSelect = value =>{
    setsubSuvc(value)
    dispatch(actions.resetSelectedItem());
    console.log(value)
    let temp = sub_vendor.filter(res => {
      return value === res.suvc
    })
    console.log(temp)
    
    setsubCompany({ suvc: temp[0].suvc, taxId: temp[0].taxId, companyName: temp[0].name, folderId: temp[0].folderId })


  }
  const fetchDetails = () =>{
    console.log(masterCompany)
    console.log(subCompany)
    // dispatch(actions.fetchCompany({ suvc: temp[0].suvc, taxId: temp[0].taxId, companyName: temp[0].name, folderId: temp[0].folderId }));
    if(Object.keys(subCompany).length>0){
      dispatch(actions.fetchCompany({ suvc: subCompany.suvc, taxId: subCompany.taxId, companyName: subCompany.name, folderId: subCompany.folderId }));
    }else{
      dispatch(actions.fetchCompany({ suvc: masterCompany.suvc, taxId: masterCompany.taxId, companyName: masterCompany.name, folderId: masterCompany.folderId }));
    }

  }
  

  const onSelect = value => {
    console.log(value)
    let temp = vendor.subSuvcs.filter(res => {
      return value === res.suvc
    })
    console.log(temp)
    dispatch(actions.fetchCompany({ suvc: temp[0].suvc, taxId: temp[0].taxId, companyName: temp[0].name, folderId: temp[0].folderId }));
  };

  const resetSelect = () => {
    dispatch(actions.resetSelectedItem());
  };

  const openDetailPage = (e) => {
    setShowDetail(true);
  };


  useEffect(() => {
    dispatch(actions.fetchOptions());
  }, [])

  console.log('userData', userData)
  return (
    <div className="main-company-wrapper">
      <Spin tip="Loading..." spinning={userFetching || companyFetching || sub_vendorfetching}>
        <DetailInfo t={t} isShow={showDetail} setShowDetail={setShowDetail} suvcDetail={selectedCompany} history={history} optionLists={optionLists} />
        <div className="detail-wrapper" style={{ display: showDetail ? 'none' : 'block' }}>
          <div className="title">{t('dei.title')}</div>
          <div>
            <span>{t('dei.hint')}</span>
            {!hasSearchPermissions && (
              <Select showSearch className="dei-select" placeholder="Select one" onSelect={onSelect} value={selectedSuvc}
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }>
                {companyList.map(item => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )

            }
            {hasSearchPermissions && (
              <div>
                <br/>
              <span>Master Company</span><Select showSearch className="dei-select" placeholder="Select one" onSelect={onMasterSelect}
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }>
                {companyList.map(item => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
              <span style={{marginLeft:'10px'}}>Sub Company</span><Select onClear={()=>{
                setsubCompany({}); 
                setsubSuvc(null);
                dispatch(actions.resetSelectedItem());
              }} showSearch allowClear={true} className="dei-select" placeholder="Select one" onSelect={onSubSelect} value={subSuvc}
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }>
                {sub_vendor.map(item => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
              <Button type="primary" onClick={fetchDetails} style={{marginLeft:'10px'}}>
                Confirm to search
              </Button>
              </div>
            )}

          </div>
          {selectedCompany && (
            <div className="company-info">
              <div className="company-title">{`${selectedCompany.companyName} - ${selectedCompany.suvc}`}</div>
              <div>Address</div>
              <div className="address-container">
                <div className="item">Street: {selectedCompany.address.street}</div>
                <div className="item">City: {selectedCompany.address.city}</div>
                <div className="item">Postal Code: {selectedCompany.address.postalCode}</div>
                <div className="item"></div>
                <div className="item">Region: {selectedCompany.address.region}</div>
                <div className="item">Country: {selectedCompany.address.country}</div>
              </div>
              <div>{selectedCompany.taxId ? <b>TAX ID: {selectedCompany.taxId}</b> : null}</div>
            </div>
          )}
          <div className="btn-footer" style={{ position: 'absolute', bottom: 0, right: 0, paddingRight: '38px' }}>
            <Button className="footer-btn btn-cancel" onClick={resetSelect}>
              CANCEL
            </Button>
            <Button
              className={classNames('footer-btn btn-confirm', { 'btn-disable': selectedCompany === null })}
              onClick={openDetailPage}
              disabled={selectedCompany === null}
            >
              ADD INFORMATION
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default withTranslation()(Home);
