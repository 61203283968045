import { takeLatest,takeEvery } from 'redux-saga/effects';
import { COMMITINFOTOSSSINNER,FETCHINFOFROMSSSINNER,CLEARFORMDATA,COMMITINFOTOSTEP,UPDATEFOLDERID ,DOWNLOADFILE,FETCHSUPPLIERS,FETCHSUBSUPPLIERS} from 'actions/actionTypes';
import {commitToSSSInner,fetchInfoFromSSS,clearFormData,commitToStep,updateFolderIdFunc,downloadFileAsync,fetchSuppliersAsync,fetchSubSuppliersAsync} from './deiCombinationSaga'

const SSScombinationSaga = [
    takeEvery(COMMITINFOTOSSSINNER.REQUEST, commitToSSSInner),
    takeLatest(FETCHINFOFROMSSSINNER.REQUEST, fetchInfoFromSSS),
    takeEvery(CLEARFORMDATA.REQUEST,clearFormData),
    takeEvery(COMMITINFOTOSTEP.REQUEST,commitToStep),
    takeEvery(UPDATEFOLDERID.REQUEST,updateFolderIdFunc),
    takeLatest(DOWNLOADFILE.REQUEST, downloadFileAsync),
    takeLatest(FETCHSUPPLIERS.REQUEST, fetchSuppliersAsync),
    takeLatest(FETCHSUBSUPPLIERS.REQUEST, fetchSubSuppliersAsync),
]

export default SSScombinationSaga;
