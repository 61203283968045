const initialState = {
  user: {
    fetching: false,
    data: {
      contactId: null,
      email: null,
      name: null,
      permissions: [],
      roles: [],
      suvc: null,
      validTill: null,
      department: null,
      selectedVendor: null
    },
    error: null
  },
  selectedItem: {
    fetching: false,
    suvc: null,
    company: null,
    error: null,
  },
  options:{
    fetchingOption:false,
    data:[],
    error:null
  },
  fetchingFSSS:{
    fetchingDataFromSSS:false,
    data:null,
    error:null
  },
  fetchingFSTEP:{
    fetchingDataFromStep:false,
    data:null,
    error:null
  },
  certification:{
    fetchingcertStatus:false,
    data:null,
    error:null
  },
  certificationToOtcs:{
    fetchingcertStatus:false,
    data:null,
    error:null
  },
  fetchUpdateFolderId:{
    fetchingUpdateFolderIdStatus:false,
    data:null,
    error:null
  },
  vendor:{
    fetchingVendor:false,
    data:null,
    error:null
  },
  subVendor:{
    fetchingSubVendor:false,
    data:[],
    error:null
  }
};

export default initialState;
