import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const FETCHCOMPANY = createRequestTypes('FETCHCOMPANY');
const FETCHOPTIONS = createRequestTypes('FETCHOPTIONS');
const CERTIFICATIONSOP = createRequestTypes('CERTIFICATIONSOP')
const RESETSELECTEDITEM = createRequestTypes('RESETSELECTEDITEM');
const COMMITINFOTOSSSINNER = createRequestTypes('COMMITINFOTOSSSINNER')
const FETCHINFOFROMSSSINNER = createRequestTypes('FETCHINFOFROMSSSINNER')
const FETCHINFOFROMSTEP = createRequestTypes('FETCHINFOFROMSTEP')
const CLEARFORMDATA = createRequestTypes('CLEARFORMDATA')
const COMMITINFOTOSTEP = createRequestTypes('COMMITINFOTOSTEP')
const CERTIFICATIONTOOTCS = createRequestTypes("CERTIFICATIONTOOTCS")
const UPDATEFOLDERID = createRequestTypes('UPDATEFOLDERID')
const DOWNLOADFILE = createRequestTypes('DOWNLOADFILE')
const FETCHSUPPLIERS = createRequestTypes('FETCHSUPPLIERS');
const FETCHSUBSUPPLIERS = createRequestTypes('FETCHSUBSUPPLIERS')
const RESELECTSUBVENDORS = createRequestTypes('RESELECTSUBVENDORS')

export { USER, FETCHCOMPANY, FETCHOPTIONS, CERTIFICATIONSOP, RESETSELECTEDITEM,COMMITINFOTOSSSINNER,FETCHINFOFROMSSSINNER ,CLEARFORMDATA,FETCHINFOFROMSTEP,COMMITINFOTOSTEP,CERTIFICATIONTOOTCS,UPDATEFOLDERID,DOWNLOADFILE,FETCHSUPPLIERS,FETCHSUBSUPPLIERS,RESELECTSUBVENDORS};
