import initialState from 'store/initialState';
import { FETCHSUPPLIERS } from 'actions/actionTypes';

const vendor = (state = initialState.vendor, action) => {
  switch (action.type) {
    case FETCHSUPPLIERS.REQUEST:
      return { ...state, fetchingVendor: true, error: null };
    case FETCHSUPPLIERS.SUCCESS:
      return { ...state, fetchingVendor: false, data: action.payload };
    case FETCHSUPPLIERS.FAILURE:
      return { ...state, fetchingVendor: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default vendor;
