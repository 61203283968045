import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { FETCHOPTIONS } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

function* fetchOptionAsync({ userId }) {
  console.log('fetchOptionAsync')
  try {
    const response = yield getRequest(`/options/optionItem?app_name=DEI`);
    yield put({ type: FETCHOPTIONS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: FETCHOPTIONS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FETCH COMPANY ERROR'
      })
    );
  }
}

export default fetchOptionAsync;
