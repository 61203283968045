import initialState from 'store/initialState';
import { CERTIFICATIONSOP } from 'actions/actionTypes';

const certification = (state = initialState.certification, action) => {
  switch (action.type) {
    case CERTIFICATIONSOP.REQUEST:
      return { ...state, fetchingcertStatus: true, error: null };
    case CERTIFICATIONSOP.SUCCESS:
      return { ...state, fetchingcertStatus: false, data: action.payload };
    case CERTIFICATIONSOP.FAILURE:
      return { ...state, fetchingcertStatus: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default certification;
