import initialState from 'store/initialState';
import { FETCHINFOFROMSTEP } from 'actions/actionTypes';

const fetchingFSTEP = (state = initialState.fetchingFSTEP, action) => {
  switch (action.type) {
    case FETCHINFOFROMSTEP.REQUEST:
      return { ...state, fetchingDataFromStep: true, error: null };
    case FETCHINFOFROMSTEP.SUCCESS:
      return { ...state, fetchingDataFromStep: false, data: action.payload };
    case FETCHINFOFROMSTEP.FAILURE:
      return { ...state, fetchingDataFromStep: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default fetchingFSTEP;
